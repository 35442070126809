// @flow
import i18n from "i18next";
import k from "src/i18n/keys";
import React from "react";

import { More } from "../styles";

import type { ReactRef } from "src/types";

type Props = {
  labelRef: ReactRef,
  showMore: boolean,
  setShowMore: (value: boolean) => void
};

const ShowMore = ({ labelRef, showMore, setShowMore }: Props) => {
  // Check if the height of actual content is greater than the
  // height of truncated element shown
  const hasLabelOverflown = labelRef.current
    ? labelRef.current.scrollHeight > labelRef.current.clientHeight
    : false;

  const expandFieldLabel = () => {
    setShowMore(true);
  };

  return hasLabelOverflown && !showMore ? (
    <More showMore={showMore} onClick={expandFieldLabel}>
      ...{i18n.t(k.MORE)}
    </More>
  ) : null;
};

export default ShowMore;
