// @flow

import styled from "@emotion/styled";

import search from "./img/search.svg";
import pageDown from "./img/pageDown.webp";
import pageUp from "./img/pageUp.webp";
import rotateCcw from "./img/rotateCcw.webp";
import rotateCw from "./img/rotateCw.webp";
import zoomIn from "./img/zoomIn.webp";
import zoomOut from "./img/zoomOut.webp";
import pdfDownload from "./img/pdf-download.webp";

const midGrey = "#807e82";
const large = "14px";
const pureWhite = "#ffffff";

export const Viewer = styled.div`
  .page {
    margin: 1px auto -8px auto;
    border: 9px solid ${midGrey};
    background-clip: content-box;
    background-color: white;
  }
`;

export const PDFToolbar = styled.div`
  top: 28px;
  width: 100%;
  height: 40px;
  position: sticky;
  margin-bottom: 1.2em;
  padding: 0 1em;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${midGrey};
  border-radius: 5px;
  font-size: ${large};
  color: ${pureWhite}

  input[type="number"] {
    width: 50px;
    border-radius: 5px;
    border: solid 1px #2e4c68;
    padding: 0.5em;
    margin-right: 0.5em;
    text-align: right;
  }

  button {
    margin-top: auto;
    margin-bottom: auto;
    background-color: transparent;
    border-color: transparent;
    border: 0;
    cursor: pointer

  &:hover {
      background-color: transparent;
      border-color: transparent;
    }

    &:focus {
      border-color: transparent;
      box-shadow: none;
    }
  }

  .previous:before {
    content: url(${pageUp});
  }

  .next:before {
    content: url(${pageDown});
  }

  .zoomin:before {
    content: url(${zoomIn});
  }

  .zoomout:before {
    content: url(${zoomOut});
  }

  .rotateccw:before {
    content: url(${rotateCcw});
  }

  .rotatecw:before {
    content: url(${rotateCw});
  }

  .download:before {
    content: url(${pdfDownload});
  }

  select {
    margin-top: auto;
    margin-bottom: auto;
    padding: 3px 2px 2px;
  }

  span.icon {
    font-size: 2em;
  }
`;

export const Find = styled.div`
  border-radius: 0.25em;
  background-repeat: no-repeat;
  background-image: url(${search});
  background-size: contain;
  max-width: 100%;

  input[type="text"] {
    width: 100%;
    border-radius: 5px;
    border: solid 1px #2e4c68;
    font-size: 14px;
    outline: none;
    color: #000;
    display: inline-block;
    resize: auto;
    margin: 0;
    padding: 0.5em;
    background-color: #fff;
    min-width: 100px;
    max-width: 130px;
  }

  > input:focus {
    outline: none;
  }
`;

export const ButtonSeparator = styled.div`
  padding: 8px 0;
  width: 1px;
  background-color: hsla(0, 0%, 0%, 0.5);
  z-index: 99;
  box-shadow: 0 0 0 1px hsla(0, 0%, 100%, 0.08);
  display: inline-block;
  margin: auto 0;
`;
