// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { useBoolean } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";

import User from "src/components/user/NameOrEmailText";
import Group from "src/components/Group/Name";
import SignatoriesModal from "src/components/Dock/Checklist/Approval/SignatoriesModal";
import { getUsersFromIds } from "src/reducers";
import { getGroupMentionId } from "src/utils";

import type { UnifizeUser, UID, ExternalAppMentions } from "src/types";

type Props = {
  uid: string | UID,
  mentions?: ExternalAppMentions
};

const MentionedUser = ({ uid, mentions = {} }: Props) => {
  if (mentions[uid]) {
    return mentions[uid];
  }

  if (uid.startsWith("signatories")) {
    return <SignatoriesMention mention={uid} />;
  }

  if (uid.startsWith("team^")) {
    return <Group id={getGroupMentionId(uid)} />;
  }

  switch (uid) {
    case "everyone":
      return <span>{i18n.t(k.ALL)}</span>;
    case "owner":
      return <span>{i18n.t(k.OWNER)}</span>;
    case "creator":
      return <span>{i18n.t(k.CREATOR)}</span>;
    default:
      return <User uid={uid} />;
  }
};

export default MentionedUser;

type SignatoriesMentionProps = {
  mention: string
};

const SignatoriesMention = ({ mention }: SignatoriesMentionProps) => {
  const userIds = mention
    .split("^")[1]
    .replace("[", "")
    .replace("]", "")
    .split(",");

  const [modalVisibility, setModalVisibility] = useBoolean();

  const users: UnifizeUser[] = useSelector(state =>
    getUsersFromIds(state.app, userIds)
  );

  return (
    <span>
      {i18n.t(k.SIGNATORIES)}
      {users.length > 0 && (
        <>
          {" "}
          {userIds[0] && <User uid={userIds[0]} />}
          {users.length > 1 && (
            <>
              {" "}
              <u style={{ cursor: "pointer" }} onClick={setModalVisibility.on}>
                {i18n.t(k._13)}
                {users.length - 1} ...{i18n.t(k.MORE)}
              </u>
              <SignatoriesModal
                title="Signatories"
                signatories={users}
                isOpen={modalVisibility}
                onClose={setModalVisibility.off}
              />
            </>
          )}
        </>
      )}
    </span>
  );
};
