// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect, useSelector } from "react-redux";
import React, { useState } from "react";

import { Base, IconWrapper, CrashWindow, CrashButton } from "./styles";
import NavBarModal from "./Modal";
import Profile from "src/components/filter/Profile";
import DirectMessages from "src/containers/filter/DirectMessages";
import MyConversation from "src/containers/filter/MyConversations";
import Setting from "src/components/filter/Setting";
import {
  getUserRole,
  getComponentPermission,
  getCurrentOrg
} from "src/reducers";
import * as atypes from "src/constants/actionTypes";
import useComponentPermission from "src/hooks/useComponentPermission";
import { componentPermissions } from "src/constants/roleManagement";

import type { AppState } from "src/types";

type Props = {
  homeScreenEnabled: boolean,
  role: string
};

const NavBar = ({ homeScreenEnabled, role }: Props) => {
  const contactsAllowed = useComponentPermission(componentPermissions.contacts);
  const manageIconAllowed = useComponentPermission(
    componentPermissions.manageIcon
  );
  const manageViewAllowed = useComponentPermission(componentPermissions.manage);
  const dashboardAllowed = useComponentPermission(
    componentPermissions.dashboard
  );
  const currentOrg = useSelector(({ app }) => getCurrentOrg(app));

  const [isCrashingWindowOpen, setIsCrashingWindowOpen] = useState(false);
  const something = undefined;

  return (
    <Base>
      <NavBarModal />
      <IconWrapper>
        <Profile />

        {(homeScreenEnabled || role === "admin" || role === "phantom") && (
          <Setting
            linkType={atypes.SET_HOME_SCREEN_REQUEST}
            icon="home"
            name={i18n.t(k.TOOLTIP_HOMESCREEN)}
            type="homescreen"
            dataCy="home-screen-request-btn"
          />
        )}

        <MyConversation />
        <DirectMessages />

        {dashboardAllowed ? (
          <Setting
            linkType={atypes.SET_DASHBOARD_REQUEST}
            icon="dashboard"
            name={i18n.t(k.TOOLTIP_DASHBOARD)}
            type="dashboard"
            dataCy="dashboard-request-btn"
          />
        ) : null}
        {contactsAllowed && (
          <Setting
            linkType={atypes.SET_CONTACTS_REQUEST}
            icon="contacts"
            name={i18n.t(k.TOOLTIP_CONTACTS)}
            type="contacts"
            dataCy="contacts-request-btn"
            linkprops={{
              meta: {
                query: {
                  sort: ["displayName:asc"],
                  status: ["Active", "Pending"]
                }
              }
            }}
          />
        )}
        {manageIconAllowed && manageViewAllowed ? (
          <Setting
            linkType={atypes.SET_PROCESS_REQUEST}
            dataCy="process-request-btn"
            icon="manage"
            name={i18n.t(k.TOOLTIP_MANAGE)}
            type="manage"
          />
        ) : null}
        {currentOrg.id === 1 && (
          <>
            <CrashButton
              data-cy="crashing-window-toggle-btn"
              onClick={() => {
                setIsCrashingWindowOpen(true);
              }}
            >
              {i18n.t(k.CRASH)}
            </CrashButton>
            {isCrashingWindowOpen && (
              <CrashWindow>
                {/* $FlowFixMe */}
                <CrashButton
                  /* $FlowFixMe */
                  onClick={something.crash()}
                  data-cy="handle-crash-btn"
                ></CrashButton>
              </CrashWindow>
            )}
          </>
        )}
      </IconWrapper>
    </Base>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  homeScreenEnabled:
    app.orgs.homeScreenEnabled &&
    getComponentPermission(app, componentPermissions.home),
  role: getUserRole(app)
});

export default connect(mapStateToProps)(NavBar);
