// @flow

import React from "react";
import { Flex, Image, Heading } from "@chakra-ui/react";

import doughnut2d from "src/assets/charts/doughnut2d.webp";
import stackedcolumn2d from "src/assets/charts/stackedcolumn2d.webp";
import comparison2d from "src/assets/charts/comparison_chart.webp";

type Props = {
  name: string,
  type?: string,
  chartType: string,
  handleType: Function,
  handleName: Function
};

const Cell = ({
  chartType,
  name,
  type = "",
  handleType,
  handleName
}: Props) => {
  const iconType = {
    "conversation-count": stackedcolumn2d,
    "conversation-count-doughnut": doughnut2d,
    "form-count": stackedcolumn2d,
    "form-count-doughnut": doughnut2d,
    "time-series": stackedcolumn2d,
    "comparison-stacked": comparison2d
  };

  const handleSelect = () => {
    handleName(name);
    handleType(type);
  };

  return (
    <Flex
      tabIndex="0"
      onClick={handleSelect}
      justifyContent="flex-end"
      alignItems="center"
      flexDirection="column"
      cursor="pointer"
      width="150px"
      p="2"
      mr="6"
      border="1px solid"
      borderColor="gray.300"
      _hover={{
        borderColor: "gray.600"
      }}
    >
      <Heading as="h6" size="14px" my="1">
        {chartType}
      </Heading>
      <Image
        src={iconType[type]}
        alt={chartType}
        mt="2"
        height="70px"
        width="80px"
      />
    </Flex>
  );
};

export default Cell;
