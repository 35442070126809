// @flow

import React from "react";
import * as R from "ramda";

import { UserSmall as StyledUser, InlineProfileImage } from "src/styles";
import { UserWithEmail } from "src/styles/user";

import type { UnifizeUser } from "src/types";

const User = ({ user, children }: { user: UnifizeUser, children: ?any }) => (
  <>
    {user.photoUrl ? (
      <UserWithEmail data-cy="user-with-email-div">
        {children}
        <InlineProfileImage
          data-cy="inline-profile-image"
          src={user.photoUrl}
        />
        <div data-cy="wrapper-user-div" className="wrapper-user">
          <div>
            <b data-cy="user-display-name">{user.displayName}</b>
          </div>
          <div data-cy="user-display-email">{user.email}</div>
        </div>
      </UserWithEmail>
    ) : (
      <UserWithEmail data-cy="user-with-email-div" key={user.uid}>
        {children}
        <StyledUser data-cy="styled-user-div">
          {R.head(user.displayName || user.email || "")}
          {R.head(R.split(" ", user.displayName || "")[1] || "")}
        </StyledUser>
        <div className="wrapper-user" data-cy="wrapper-user-div">
          <div>
            <b data-cy="user-display-name">{user.displayName}</b>
          </div>
          <div data-cy="user-display-email">{user.email}</div>
        </div>
      </UserWithEmail>
    )}
  </>
);

export default User;
