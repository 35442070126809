// @flow
import React, { useCallback, useState, useEffect, useMemo } from "react";
import * as R from "ramda";
import { useSelector, useDispatch } from "react-redux";
import i18n from "i18next";
import k from "src/i18n/keys";

import type {
  FieldId,
  RoomId,
  ColumnId,
  FieldValue,
  HttpMethods,
  RichTextFieldValue
} from "src/types";

import {
  updateChecklistFromManageView,
  bulkUpdateProcess
} from "src/actions/workflows";
import { setChecklistValue } from "src/actions/checklist";
import OutsideClickHandler from "src/components/OutsideClickHandler";
import useKeyDownHandlers from "src/hooks/useKeyDownHandlers";
import useRichTextEditor from "src/hooks/useRichTextEditor";
import {
  getChecklistValue,
  getChecklistFieldDetails,
  getWhetherMandatoryField,
  getLockedStatus,
  getChecklistFormValue,
  getIsFieldLocked,
  getFormFieldMandatoryStatus,
  getSelectedChecklist,
  isProcessRowSelected,
  getLastChat,
  getCurrentUserId
} from "src/reducers";
import { getSettings, getFieldBorderColor } from "src/utils/checklist";
import { getChecklistNotificationAuthor } from "src/utils/chatroom";
import { extractChecklistValue, getPlaceholder } from "src/utils/checklist";
import { RICH_TEXT_BLOB_SIZE_LIMIT } from "src/constants/checklist";

import {
  RichText as StyledRichText,
  TextFieldContainer,
  Done,
  Discard,
  TextFieldButtons
} from "../styles";
import AddData from "../AddData";
import TextFieldUpdate from "../Text/TextFieldUpdate";

type Props = {
  formId?: ?number,
  roomId: RoomId,
  fieldId: FieldId,
  roomFieldFormId?: ?string,
  fromManageView?: boolean,
  handleClose?: Function
};

const RichText = ({
  formId,
  roomId,
  fieldId,
  roomFieldFormId,
  fromManageView = false,
  handleClose
}: Props) => {
  const dispatch = useDispatch();

  const isChecklistFieldMandatory = useSelector(({ app }) =>
    getWhetherMandatoryField(app, roomId, fieldId)
  );
  const isFormFieldMandatory = useSelector(({ app }) =>
    getFormFieldMandatoryStatus(app, roomFieldFormId ?? "")
  );
  const isMandatory = formId ? isFormFieldMandatory : isChecklistFieldMandatory;
  const isChecklistFieldLocked = useSelector(({ app }) =>
    getLockedStatus(app, roomId, fieldId)
  );
  const isFormFieldLocked = useSelector(({ app }) =>
    getIsFieldLocked(app, roomFieldFormId, fieldId, roomId)
  );
  const locked = formId ? isFormFieldLocked : isChecklistFieldLocked;

  const {
    columnId,
    value: selectedChecklistValue,
    roomId: selectedRoomId,
    index,
    embeddedIndex
  } = useSelector(({ app }) => getSelectedChecklist(app));

  const rowSelected = useSelector(({ app }) =>
    isProcessRowSelected(app, selectedRoomId)
  );

  const originalRoomId = fromManageView ? selectedRoomId : roomId;
  const checklistFieldValue = useSelector(({ app }) =>
    getChecklistValue(app, fieldId, originalRoomId)
  );
  const formFieldValue = useSelector(({ app }) =>
    getChecklistFormValue(app, roomFieldFormId ?? "")
  );
  const extractedValue = fromManageView
    ? selectedChecklistValue
    : extractChecklistValue(formId ? formFieldValue : checklistFieldValue);

  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${fieldId}`)
  );
  const placeholder = getPlaceholder(
    //$FlowFixMe Method calls in optional chain
    getSettings(details?.get("settings")),
    i18n.t(k.ENTER_TEXT)
  );
  const lastMessage = useSelector(({ app }) => getLastChat(app, roomId));
  const currentUserId = useSelector(({ app }) => getCurrentUserId(app));
  const checklistUpdatedByOtherUser = useMemo(
    () => getChecklistNotificationAuthor(lastMessage) !== currentUserId,
    [lastMessage, currentUserId]
  );

  const handleKeyDown = (e: any) => {
    if (!editing) return;
    // If escape is pressed cancel editing
    if (e.keyCode === 27 && setFieldValue) {
      handleDiscardValue();
    } else {
      handleShiftEnter(e);
    }
  };

  const handleClick = () => {
    if (locked || editing || fromManageView) return;
    setEditing(true);
  };

  const [modified, setModified] = useState(false);
  const [fieldValue, setFieldValue] = useState<RichTextFieldValue | string>(
    extractedValue
  );
  const {
    Editor: RichTextEditor,
    editing,
    setEditing,
    editor
  } = useRichTextEditor(
    placeholder,
    fieldValue,
    locked,
    handleClick,
    handleKeyDown
  );

  useEffect(() => {
    if (editing && checklistUpdatedByOtherUser) {
      // Don't update value for text fields while editing
      setModified(true);
    } else if (extractedValue !== fieldValue) {
      setFieldValue(extractedValue);
    }
  }, [extractedValue, checklistUpdatedByOtherUser, setModified, setFieldValue]);

  useEffect(() => {
    if (modified && !editing) {
      setModified(false);
    }
  }, [modified, editing]);

  const settings: any = getSettings(details ? details.get("settings") : "{}");
  const multiple = settings?.multiple || false;

  const setChecklistValueFromManageView = useCallback(
    ({
      id: fieldId,
      value: fieldDetail,
      httpMethod,
      extraBody = null,
      columnId
    }: {
      id: FieldId,
      value: FieldValue,
      httpMethod?: HttpMethods,
      extraBody?: Object,
      columnId?: ColumnId
    }) => {
      const { value, type } = fieldDetail;
      if (rowSelected) {
        if (!multiple) {
          return bulkUpdateProcess({
            attrs: {
              [fieldId]: value
            }
          });
        }
      } else {
        return updateChecklistFromManageView(
          selectedRoomId,
          {
            [fieldId]: value,
            type,
            value
          },
          index,
          fieldId,
          httpMethod,
          extraBody,
          columnId ?? "",
          embeddedIndex
        );
      }

      if (!multiple && rowSelected && handleClose) {
        handleClose();
      }
    },
    [rowSelected, selectedRoomId, index, embeddedIndex, multiple, handleClose]
  );

  const setChecklistFieldValue = useCallback(
    ({
      roomId,
      id,
      value,
      progress,
      formId,
      httpMethod,
      extraBody
    }: {
      roomId: RoomId,
      id: FieldId,
      value: FieldValue,
      progress: boolean,
      formId?: ?number,
      httpMethod?: HttpMethods,
      extraBody?: Object
    }) => {
      if (fromManageView) {
        return setChecklistValueFromManageView({
          columnId,
          extraBody,
          formId,
          httpMethod,
          id,
          progress,
          value
        });
      } else {
        return setChecklistValue({
          roomId,
          id: fieldId,
          value,
          progress: true,
          formId,
          columnId
        });
      }
    },
    [fromManageView, columnId, fieldId, setChecklistValueFromManageView]
  );

  const updateChecklistValue = useCallback(() => {
    const plainText: string = (editor.getText() ?? "").replace(
      /^\s*[\r\n]/gm,
      ""
    ); // Remove all empty newlines
    const htmlText = editor.getHTML();
    const byteSize = new Blob([htmlText, plainText]).size;
    const richTextValue: RichTextFieldValue = {
      htmlText,
      plainText
    };
    setFieldValue(richTextValue?.plainText === "" ? null : richTextValue);

    if (
      extractedValue?.htmlText !== htmlText && // Compare both values in HTML text
      byteSize < RICH_TEXT_BLOB_SIZE_LIMIT
    )
      dispatch(
        setChecklistFieldValue({
          roomId,
          id: fieldId,
          value: {
            value: plainText === "" ? null : richTextValue,
            type: "richtext",
            checked: !!plainText
          },
          progress: true,
          formId,
          columnId
        })
      );

    setEditing(false);
  }, [roomId, formId, columnId, fieldId, editor]);

  const { handleShiftEnter } = useKeyDownHandlers(updateChecklistValue);

  const handleDiscardValue = useCallback(() => {
    if (setFieldValue) {
      setFieldValue(extractedValue);
      if (editing) {
        setEditing(false);
      }
    }
  }, [extractedValue, editor, setFieldValue]);

  if (!editing && (R.isNil(fieldValue) || R.isEmpty(fieldValue))) {
    return (
      <AddData
        disabled={locked || fromManageView}
        type="richtext"
        handleClick={handleClick}
        isSelect={false}
        isMandatory={isMandatory}
      />
    );
  }
  return (
    <OutsideClickHandler
      onClickOutside={editing ? updateChecklistValue : () => {}}
    >
      <TextFieldContainer>
        <StyledRichText
          disabled={locked}
          modified={modified}
          border={getFieldBorderColor(modified, editing, isMandatory)}
        >
          <RichTextEditor />
          {editing && (
            <TextFieldButtons>
              {modified ? (
                <Discard data-cy="textDone" onClick={handleDiscardValue}>
                  {i18n.t(k.DISCARD)}
                </Discard>
              ) : null}
              <Done data-cy="textDone" onClick={updateChecklistValue}>
                {i18n.t(k.DONE)}
              </Done>
            </TextFieldButtons>
          )}
        </StyledRichText>
        <TextFieldUpdate
          lastMessage={lastMessage}
          modified={modified}
          edit={editing}
        />
      </TextFieldContainer>
    </OutsideClickHandler>
  );
};

export default RichText;
