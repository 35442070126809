// @flow

import { connect } from "react-redux";
import React, { useRef, useState } from "react";

import { Info } from "../styles";
import Tooltip from "../NavBar/Tooltip/styles";
import UpdateDot from "./UpdateDot";
import User from "src/containers/user.old";
import ProfileMenu from "src/components/filter/Profile/ProfileMenu";
import { getCurrentUser } from "src/reducers";
import Rectangle from "./Rectangle.svg";
import { BorderImage } from "./styles";

import type { AppState, UnifizeUser } from "src/types";

type Props = {
  currentUser: UnifizeUser,
  view: string
};

const Profile = ({ currentUser, view }: Props) => {
  const [isProfileMenu, setProfileMenu] = useState(false);
  const { uid, displayName, email } = currentUser;
  const menuRef: { current: HTMLDivElement | null } = useRef(null);
  return (
    <Info
      ref={menuRef}
      data-cy="profile-component"
      onClick={e => {
        e.stopPropagation();
        setProfileMenu(!isProfileMenu);
      }}
    >
      <Tooltip data-cy="profile-tooltip" selected={isProfileMenu}>
        {view === "orgSettings" && (
          <BorderImage data-cy="border-image-rectangle" src={Rectangle} />
        )}
        <UpdateDot />
        <User active={view === "orgSettings"} type="profileImage" uid={uid} />
        <span data-cy="user-display-name-span">
          {(displayName || email.split("@")[0]).toUpperCase()}
        </span>
      </Tooltip>

      {isProfileMenu ? (
        <ProfileMenu
          handleClose={() => setProfileMenu(false)}
          outerRef={menuRef}
        />
      ) : null}
    </Info>
  );
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  currentUser: getCurrentUser(app),
  view: app.view
});

export default connect(mapStateToProps)(Profile);
