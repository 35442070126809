// @flow

import { connect } from "react-redux";
import React from "react";

import { UpdateDot as StyledUpdateDot } from "./styles";

import type { AppState, Updates as UpdatesType } from "src/types";

type Props = {
  updates: UpdatesType
};

const UpdateDot = ({ updates }: Props) => {
  if (process.env.VERSION !== updates.newVersion && updates.newVersion) {
    return <StyledUpdateDot data-cy="update-dot" />;
  }
  return <></>;
};

const mapStateToProps = ({ app }: { app: AppState }) => ({
  updates: app.updates
});

export default connect(mapStateToProps)(UpdateDot);
