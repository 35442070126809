// @flow

import React from "react";
import * as R from "ramda";

import { NavProfileImage, NoProfilePic } from "src/styles";

import type { UnifizeUser } from "src/types";

type Props = { user: UnifizeUser, active: ?boolean };

const UserName = ({ user, active }: Props) => (
  <>
    {user.photoUrl ? (
      <NavProfileImage data-cy="nav-profile-image-div" active={active}>
        <img
          data-cy="user-image"
          width="30"
          height="30"
          src={user.photoUrl}
          alt="profile-pic"
        />
      </NavProfileImage>
    ) : (
      <NavProfileImage data-cy="nav-profile-image-div" active={active}>
        <NoProfilePic data-cy="no-profile-pic-div" active={active}>
          {R.head(user.displayName || user.email || "")}
          {R.head(R.split(" ", user.displayName || "")[1] || "")}
        </NoProfilePic>
      </NavProfileImage>
    )}
  </>
);

export default UserName;
