// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as R from "ramda";
import { VStack } from "@chakra-ui/react";

import useBoolean from "src/hooks/useBoolean";
import WorkflowName from "src/containers/workflow/Name";
import Dropdown from "src/components/Dock/Checklist/Conversation/Dropdown";
import ChatroomCard from "src/components/Dock/Checklist/Conversation/FieldItem";
import NewRoom from "src/components/Dock/Checklist/Conversation/NewRoom";
import SingleModal from "./SingleModal";
import RemoveLinkModal from "./RemoveLinkModal";
import ButtonLoader from "src/components/Dock/Checklist/Conversation/Loader";

import { setChecklistValue } from "src/actions/checklist";
import {
  updateChecklistFromManageView,
  bulkUpdateProcess
} from "src/actions/workflows";
import { getSettings } from "src/utils/checklist";

import {
  getChecklistValue,
  getChecklistFieldDetails,
  getSelectedChecklist,
  getArchivedConversations,
  getWorkflow,
  getCurrentUser,
  getLockedStatus,
  getWhetherMandatoryField,
  getBehaviorByFormField,
  getChecklistFieldBehavior,
  getRoomFieldValueStatus,
  isProcessRowSelected
} from "src/reducers";

import { dataStages } from "src/constants";
import httpMethods from "src/constants/httpMethods";
import {
  behaviorToSettings,
  behaviors,
  getDependentOptions
} from "src/conditions";
import { filterArchived } from "./utils";

import { AddButton } from "../styles";
import type { RoomId, FieldId, UID, HttpMethods } from "src/types";

type Props = {
  roomId: RoomId,
  fieldId: FieldId,
  formId: ?number,
  fromManageView: ?boolean,
  roomFieldFormId: ?string
};

const Single = ({
  roomId,
  fieldId,
  formId,
  fromManageView,
  roomFieldFormId
}: Props) => {
  const dispatch = useDispatch();

  const {
    columnId,
    embeddedIndex,
    value: selectedValue,
    roomId: editRoomId,
    index
  } = useSelector(({ app }) => getSelectedChecklist(app));

  const rowSelected = useSelector(({ app }) =>
    isProcessRowSelected(app, editRoomId)
  );

  const checklistValue = useSelector(({ app }) =>
    getChecklistValue(app, fieldId, roomId)
  );
  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${fieldId}`)
  );
  const archived: Array<RoomId> =
    useSelector(({ app }) => getArchivedConversations(app, checklistValue)) ||
    [];

  const locked = useSelector(({ app }) =>
    getLockedStatus(app, roomId, fieldId)
  );
  const isMandatory = useSelector(({ app }) =>
    getWhetherMandatoryField(app, roomId, fieldId)
  );
  const behaviorByFormField = useSelector(({ app }) =>
    getBehaviorByFormField(app, roomFieldFormId || "")
  );
  const behaviorByChecklistField =
    useSelector(({ app }) => getChecklistFieldBehavior(app, roomId, fieldId)) ||
    {};
  const valueStatus = useSelector(({ app }) =>
    getRoomFieldValueStatus(app, fieldId, roomId)
  );
  const updating = valueStatus === dataStages.updating;

  const behavior = roomFieldFormId
    ? behaviorByFormField
    : behaviorByChecklistField;

  const dependentOptions = behavior.options;
  const currentBehavior = behavior.current;

  const settings: any = getSettings(details ? details.get("settings") : "{}");

  const filteredValues = settings?.showArchived
    ? checklistValue?.value || {}
    : filterArchived(checklistValue?.value || {}, archived);
  const existingValues = fromManageView ? selectedValue : filteredValues;

  const workflowData = useSelector(({ app }) =>
    getWorkflow(app, `${settings?.workflow ?? ""}`)
  );
  const currentUser = useSelector(({ app }) => getCurrentUser(app));

  // $FlowFixMe - Optional chaining not yet supported
  const target = details?.get("target") || false;

  const { entities: { chatrooms = {} } = {}, result = [] } =
    existingValues || {};

  const value = R.isEmpty(result) || !chatrooms ? null : chatrooms[result[0]];

  const {
    value: isDropdownVisible,
    setTrue: openDropdown,
    setFalse: closeDropdown,
    setValue: setDropdown
  } = useBoolean(false);

  const dropdown = {
    visible: isDropdownVisible,
    set: setDropdown,
    open: openDropdown
  };

  const [modalState, setModalState] = useState({
    open: false,
    roomId: null,
    edit: false
  });

  const closeModal = () => setModalState(prev => ({ ...prev, open: false }));

  const modal = {
    state: modalState,
    close: closeModal
  };

  const { value: newRoom, setValue: setNewRoom } = useBoolean();

  const {
    value: isRemoveLinkModalVisible,
    setTrue: showRemoveLinkModal,
    setFalse: hideRemoveLinkModal
  } = useBoolean(false);

  const handleClose = () => {
    setNewRoom(false);
    closeDropdown();
  };

  const handleNewRoom = () => {
    setNewRoom(true);
    closeDropdown();
  };

  const setChecklistFieldValue = useCallback(
    ({
      roomId,
      id,
      value,
      progress,
      formId,
      httpMethod,
      extraBody
    }: {
      roomId: RoomId,
      id: FieldId,
      value: Object,
      progress: boolean,
      formId: ?number,
      httpMethod?: HttpMethods,
      extraBody?: Object
    }) => {
      // Use updateChecklistFromManageView to update embedded linked
      // fields from manage view
      if (fromManageView && columnId) {
        // Bulk update
        if (rowSelected) {
          return bulkUpdateProcess({
            attrs: {
              [id]: value.value
            }
          });
        }
        // Individual record update
        return updateChecklistFromManageView(
          editRoomId,
          value,
          index,
          fieldId,
          httpMethod,
          extraBody,
          columnId,
          embeddedIndex
        );
      } else {
        // To update linked field values on Checklist Dock
        return setChecklistValue({
          roomId,
          id,
          value,
          progress,
          formId,
          httpMethod,
          extraBody,
          columnId,
          embeddedIndex
        });
      }
    },
    []
  );

  const handleCreate = title => {
    const newRoomData: {
      title: string,
      type: string,
      owner: UID | null,
      creator: UID,
      members: UID[],
      templateId: number,
      privacy: string,
      parent?: number,
      groups: number[]
    } = {
      title,
      type: "workflow",
      owner: workflowData.owner || null,
      creator: currentUser.uid,
      members: workflowData.members,
      templateId: settings.workflow,
      privacy: workflowData.privacy,
      groups: workflowData?.groups ?? []
    };

    // Assign creator as owner depending on setting
    if (
      !newRoomData.owner &&
      workflowData?.settings?.creatorIsOwner !== false
    ) {
      newRoomData.owner = currentUser.uid;
    }

    // Make creator as member depending on setting
    if (workflowData?.settings?.creatorIsParticipant !== false) {
      if (newRoomData.owner) {
        newRoomData.members = R.uniq([
          newRoomData.owner,
          currentUser.uid,
          ...newRoomData.members
        ]);
      } else {
        newRoomData.members = R.uniq([currentUser.uid, ...newRoomData.members]);
      }
    }

    if (settings.child) {
      newRoomData.parent = parseInt(roomId, 10);
    }

    dispatch(
      setChecklistFieldValue({
        roomId,
        id: fieldId,
        value: {
          value: {
            chatroom: newRoomData,
            linkAllVersions: true
          },
          type: "link"
        },
        progress: true,
        formId,
        httpMethod: httpMethods.patch,
        columnId,
        embeddedIndex
      })
    );

    handleClose();
  };

  const handleVersionClick = id => {
    setModalState({
      open: true,
      edit: fromManageView ? true : false,
      roomId: id
    });
  };

  const selectNewRoomVersions = id => {
    setModalState({
      open: true,
      edit: true,
      roomId: id
    });
  };

  const removeLink = originChatroomId => {
    dispatch(
      setChecklistFieldValue({
        roomId: `${roomId}`,
        id: fieldId,
        value: {
          value: {
            originChatroomId
          },
          type: "link"
        },
        progress: true,
        formId,
        columnId,
        httpMethod: httpMethods.delete,
        embeddedIndex
      })
    );
  };

  const removeRoom = originChatroomId => {
    dispatch(
      setChecklistFieldValue({
        roomId: `${roomId}`,
        id: fieldId,
        value: {
          value: { originChatroomId },
          type: "link"
        },
        httpMethod: httpMethods.delete,
        columnId,
        formId,
        progress: true,
        embeddedIndex
      })
    );
  };

  const handleRemove = () => {
    if (!target) {
      removeRoom(value?.originChatroomId);
    } else {
      showRemoveLinkModal();
    }
  };

  const editParentField = ({ newValue, sourceChatroom }) => {
    dispatch(
      setChecklistFieldValue({
        roomId,
        id: fieldId,
        value: {
          value: newValue,
          type: "link"
        },
        httpMethod: httpMethods.patch,
        extraBody: {
          sourceChatroom
        },
        progress: true,
        formId,
        columnId,
        embeddedIndex
      })
    );
  };

  const handledEditParentField = newVersion => {
    editParentField({
      newValue: newVersion,
      sourceChatroom: value?.originChatroomId
    });
  };

  const handleRemoveLink = () => {
    const roomId = value?.originChatroomId;
    if (roomId) {
      removeLink(roomId);
    }
  };

  const selectChatroom = value => {
    dispatch(
      setChecklistFieldValue({
        roomId,
        id: fieldId,
        value: {
          value,
          type: "link"
        },
        progress: true,
        formId,
        httpMethod: httpMethods.patch
      })
    );
    //if (promptCallback) promptCallback();
  };

  const handleSelect = id => {
    selectChatroom({ chatroom: id, linkAllVersions: true });
    handleClose();
  };

  const dependentInclude = getDependentOptions(
    currentBehavior,
    behaviorToSettings[behaviors.dependentLinkedFieldInclude],
    dependentOptions
  );

  const dependentExclude = getDependentOptions(
    currentBehavior,
    behaviorToSettings[behaviors.dependentLinkedFieldExclude],
    dependentOptions
  );

  // NOTE: Why are we using settings.showCompressedPreview
  // instead of settings.showFieldNames
  return (
    <VStack alignItems="start" width="100%">
      {!dropdown.visible && !newRoom && value && (
        <ChatroomCard
          value={value.chatroom.id}
          roomId={roomId}
          fieldId={fieldId}
          showFieldNames={settings.showCompressedPreview}
          handleRemove={handleRemove}
          setDropdown={dropdown.set}
          onVersionClick={handleVersionClick}
          autoLink={value.linkAllVersions || false}
          fromManageView={fromManageView ?? false}
        />
      )}

      {dropdown.visible && (
        <Dropdown
          roomId={roomId}
          showRemove={Boolean(value)}
          workflow={settings.workflow}
          type="workflow"
          selectedValue={value ? [value.chatroom.id] : []}
          handleClose={handleClose}
          handleNewRoom={handleNewRoom}
          handleSelect={handleSelect}
          handleCreate={handleCreate}
          handleRemove={handleRemove}
          create={settings.create}
          select={settings.select}
          groupVersions
          selectNewRoomVersions={selectNewRoomVersions}
          hideSelectedVersions
          dependentInclude={dependentInclude}
          dependentExclude={dependentExclude}
          alwaysShowRevisionIcon={Boolean(settings.alwaysAllowManualLinks)}
          fromManageView={fromManageView ?? false}
          showArchived={settings.showArchived}
        />
      )}

      {updating ? (
        <ButtonLoader />
      ) : (
        !newRoom &&
        !dropdown.visible &&
        !value && (
          <AddButton
            data-cy="addConversationButton"
            type="button"
            onClick={openDropdown}
            disabled={locked}
            isMandatory={isMandatory}
          >
            {i18n.t(k.ADD1)} <WorkflowName id={settings.workflow} />
          </AddButton>
        )
      )}

      {newRoom && !dropdown.visible && (
        <NewRoom
          settings={settings}
          handleClose={handleClose}
          handleCreate={handleCreate}
          isMandatory={isMandatory}
        />
      )}

      {modal.state.open && !R.isNil(modal.state.roomId) && (
        <SingleModal
          open
          roomId={modal.state.roomId}
          value={value}
          autoLink={Boolean(value?.linkAllVersions)}
          defaultEdit={modal.state.edit}
          selectChatroom={selectChatroom}
          onClose={modal.close}
        />
      )}

      {value && isRemoveLinkModalVisible && (
        <RemoveLinkModal
          open
          onClose={hideRemoveLinkModal}
          roomId={roomId}
          fieldId={fieldId}
          sourceChatroomId={value?.originChatroomId}
          handledEditParentField={handledEditParentField}
          handleRemoveLink={handleRemoveLink}
        />
      )}
    </VStack>
  );
};

export default Single;
