// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import { connect } from "react-redux";
import React, { useCallback, useEffect, useMemo } from "react";
import * as R from "ramda";
import { Text } from "@unifize/sarah";

import {
  Conversation as StyledConversation,
  ListItem as StyledListItem,
  Button as ButtonOutline,
  ChatroomName as StyledChatroomName
} from "./styles";
import { showView } from "src/actions";
import {
  setPreviousChatroom,
  fetchChatroomUpdates
} from "src/actions/chatroom";
import { ButtonLink } from "src/styles/buttons";
import { getFilteredConversations, getAllTemplateStatus } from "src/reducers";
import Link from "src/containers/chatroom/Name/Title";
import Name from "src/containers/chatroom/Name";
import { updateFilter } from "src/actions/filter";
import { initialFilterState } from "src/reducers/chatRooms";
import { openHomeScreenTile, setTileEmptyStatus } from "src/actions/homescreen";
import tileColors from "src/styles/constants/tileColors";

import type { AppState, HomescreenTile, Action } from "src/types";

type Props = {
  rooms: Array<number>,
  tile: HomescreenTile,
  _showView: Function,
  _setPreviousChatroom: Function,
  _updateFilter: Function,
  _openHomeScreenTile: Function,
  isRoomCancelled: Function,
  _fetchChatroomUpdates: Function,
  _setTileEmptyStatus: (
    sectionId: number,
    tileId: number,
    isEmpty: boolean
  ) => Action,
  sectionId: number
};

const Conversations = ({
  rooms,
  tile,
  _showView,
  _setPreviousChatroom,
  _openHomeScreenTile,
  _updateFilter,
  _fetchChatroomUpdates,
  _setTileEmptyStatus,
  sectionId
}: Props) => {
  const handleNavigate = useCallback(() => {
    _updateFilter({
      ...initialFilterState,
      ...(tile.settings.filters || {}),
      name: tile.title || "My Inbox",
      homescreen: true
    });
    _setPreviousChatroom();
    _showView("My Inbox");
    _openHomeScreenTile({ type: "clicked-tile", tileType: "My Inbox" });
    _fetchChatroomUpdates();
  }, [tile, _setPreviousChatroom, _showView, _updateFilter]);

  const handleClick = useCallback(() => {
    if (rooms.length > 5) {
      handleNavigate();
    }
  }, [rooms]);

  const remaining = rooms.length - 5;

  const colorScheme = tileColors[tile.settings.tileColor];

  const isTileHidden = useMemo(
    () => rooms.length === 0 && !tile.settings.displayEmpty,
    [rooms.length, tile.settings]
  );

  useEffect(() => {
    _setTileEmptyStatus(sectionId, tile.id, isTileHidden);
  }, [rooms.length, isTileHidden]);

  if (isTileHidden) {
    return <></>;
  }

  return (
    <StyledConversation
      clickable={rooms.length > 5}
      color={tile.settings.tileColor}
      onClick={handleClick}
      textColor={colorScheme.mainText}
    >
      {rooms.length > 0 && (
        <>
          <Text variant="body" weight="bold" color={colorScheme.title}>
            {tile.title}
          </Text>
          {(tile.subTitle || "").length > 0 && (
            <Text variant="caption" color={colorScheme.subTitle}>
              {(tile.subTitle || "").replace(/#num/g, `${rooms.length || ""}`)}
            </Text>
          )}
        </>
      )}
      {rooms.length === 0 ? (
        <>
          <Text variant="body" weight="bold" color={colorScheme.title}>
            {tile.settings.emptyMessage?.title || ""}
          </Text>
          <Text variant="caption" color={colorScheme.subTitle}>
            {tile.settings.emptyMessage?.subTitle || ""}
          </Text>
        </>
      ) : (
        <ul>
          {R.take(5, rooms).map(room => (
            <StyledListItem key={room}>
              {rooms.length > 5 ? (
                <StyledChatroomName>
                  <Name id={room} />
                </StyledChatroomName>
              ) : (
                <Link id={room} openChatroomModal={false} />
              )}
            </StyledListItem>
          ))}
        </ul>
      )}
      {remaining > 0 && (
        <ButtonLink
          type="button"
          onClick={handleNavigate}
          color={colorScheme.secondaryText}
          style={{ marginRight: "auto" }}
        >
          {i18n.t(k._13)} {remaining} {i18n.t(k.MORE)}
        </ButtonLink>
      )}
      {rooms.length > 0 &&
        R.trim(tile.settings.buttonText || "").length > 0 && (
          <ButtonOutline
            type="button"
            onClick={handleNavigate}
            color={colorScheme.secondaryText}
            style={{ marginRight: "auto" }}
          >
            {tile.settings.buttonText}
          </ButtonOutline>
        )}
    </StyledConversation>
  );
};

const mapStateToProps = ({ app }: { app: AppState }, props) => {
  const templateStatus = getAllTemplateStatus(app);
  return {
    rooms: getFilteredConversations(
      app,
      {
        ...initialFilterState,
        ...(props.tile.settings.filters || {})
      },
      templateStatus
    )
  };
};

export default connect(mapStateToProps, {
  _showView: showView,
  _setPreviousChatroom: setPreviousChatroom,
  _updateFilter: updateFilter,
  _openHomeScreenTile: openHomeScreenTile,
  _fetchChatroomUpdates: fetchChatroomUpdates,
  _setTileEmptyStatus: setTileEmptyStatus
})(Conversations);
