import i18n from "i18next";
import k from "src/i18n/keys";

import React, { useState } from "react";

import {
  MenuItemWithIcon,
  HoverExtender,
  UserSubMenu,
  MenuItem
} from "../styles";
import AllUsers from "src/components/filter/AllUsers";
import Icon from "src/icons";

const ViewAs = () => {
  const [userMenu, setUserMenu] = useState(false);

  return (
    <MenuItem
      data-cy="handle-view-as-li"
      onClick={e => e.stopPropagation()}
      onMouseEnter={() => setUserMenu(true)}
      onMouseLeave={() => setUserMenu(false)}
    >
      <MenuItemWithIcon>
        <Icon type="profile" />
        <span>{i18n.t(k.VIEW_AS)}</span>
      </MenuItemWithIcon>
      {userMenu ? <HoverExtender /> : null}
      {userMenu ? (
        <UserSubMenu>
          <AllUsers />
        </UserSubMenu>
      ) : null}
    </MenuItem>
  );
};

export default ViewAs;
