// @flow
import i18n from "i18next";
import k from "src/i18n/keys";

import React from "react";
import * as R from "ramda";

import { UserSmall as StyledUser, InlineProfileImage } from "src/styles";
import { UserDisplayName, Pending } from "src/styles/user";

import type { UnifizeUser } from "src/types";

const UserWithDisplayName = ({
  user,
  children,
  remove,
  clickHandler
}: {
  user: UnifizeUser,
  children: ?any,
  remove: ?boolean,
  clickHandler: ?Function
}) => (
  <>
    {user.photoUrl ? (
      <UserDisplayName data-cy="user-display-name-div">
        {children}
        <InlineProfileImage
          data-cy="inline-profile-image"
          src={user.photoUrl}
        />
        <span data-cy="user-display-name-span" title={user.displayName}>
          {user.displayName}
          {remove ? (
            <button data-cy="remove-btn" type="button" onClick={clickHandler}>
              {i18n.t(k.X3)}
            </button>
          ) : (
            ""
          )}
        </span>
        {user.invitePending ? (
          <Pending data-cy="pending-span">{i18n.t(k.PENDING)}</Pending>
        ) : null}
      </UserDisplayName>
    ) : (
      <UserDisplayName key={user.uid} data-cy="user-display-name-div">
        {children}
        <StyledUser data-cy="styled-user-div">
          {R.head(user.displayName || user.email || "")}
          {R.head(R.split(" ", user.displayName || "")[1] || "")}
        </StyledUser>
        <span
          data-cy="user-display-name-email-span"
          title={user.displayName ? user.displayName : user.email}
        >
          {user.displayName ? user.displayName : user.email}
          {remove ? (
            <button data-cy="remove-btn" type="button" onClick={clickHandler}>
              {i18n.t(k.X3)}
            </button>
          ) : (
            ""
          )}
        </span>
        {user.invitePending ? (
          <Pending data-cy="pending-span">{i18n.t(k.PENDING)}</Pending>
        ) : null}
      </UserDisplayName>
    )}
  </>
);

export default UserWithDisplayName;
