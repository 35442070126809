// @flow

import { put, select, take, takeLatest } from "redux-saga/effects";

import { getLastOrg } from "src/selectors";
import * as atypes from "src/constants/actionTypes";
import { rsf } from "src/db";

function* syncDepartment(): any {
  try {
    const orgId = yield select(getLastOrg);
    const channel = rsf.firestore.channel(`orgs/${orgId}/departments`);

    while (true) {
      const snapshot = yield take(channel);
      const departments = [];
      for (const doc of snapshot.docs) {
        departments.push({
          ...doc.data(),
          id: parseInt(doc.id, 10)
        });
      }

      yield put({
        type: atypes.SYNC_DEPARTMENTS_SUCCESS,
        payload: { departments }
      });
    }
  } catch (error) {
    yield put({
      type: atypes.SYNC_DEPARTMENTS_FAILURE,
      payload: {
        error
      }
    });
  }
}

function* watchSyncDepartment(): any {
  yield takeLatest(atypes.SET_CONTACTS_REQUEST, syncDepartment);
}

export default [watchSyncDepartment()];
