// @flow

import React, { useState, useCallback, useMemo } from "react";
import * as R from "ramda";
import { useSelector, useDispatch } from "react-redux";

import { VStack } from "@chakra-ui/react";
import SelectionMultiple from "src/components/Dock/Checklist/Conversation/SelectionMultiple";
import Dropdown from "src/components/Dock/Checklist/Conversation/Dropdown";
import NewMultiSelectConversation from "src/components/Dock/Checklist/Conversation/NewMultiSelectConversation";
import NewRoom from "src/components/Dock/Checklist/Conversation/NewRoom";
import MultiModal from "./MultiModal";
import RemoveLinkModal from "./RemoveLinkModal";
import useBoolean from "src/hooks/useBoolean";

import { setChecklistValue } from "src/actions/checklist";
import {
  updateChecklistFromManageView,
  updateSelectedChecklistRequest
} from "src/actions/workflows";
import { getSettings } from "src/utils/checklist";

import {
  getMutualVersions,
  getChecklistValue,
  getChecklistFieldDetails,
  getSelectedChecklist,
  getArchivedConversations,
  getWorkflow,
  getCurrentUser,
  getLockedStatus,
  getWhetherMandatoryField,
  getBehaviorByFormField,
  getChecklistFieldBehavior,
  getRoomFieldValueStatus,
  getWorkflowSelectedRows
} from "src/reducers";
import {
  getOriginChatroomId,
  groupVersionsTogether,
  filterArchived
} from "./utils";
import { dataStages } from "src/constants";
import {
  behaviorToSettings,
  behaviors,
  getDependentOptions
} from "src/conditions";
import httpMethods from "src/constants/httpMethods";

import type { RoomId, FieldId, UID, HttpMethods, ColumnId } from "src/types";

type Props = {
  roomId: RoomId,
  fieldId: FieldId,
  formId: ?number,
  fromManageView: ?boolean,
  roomFieldFormId: ?string
};

const Multi = ({
  roomId,
  fieldId,
  formId,
  fromManageView,
  roomFieldFormId
}: Props) => {
  const dispatch = useDispatch();

  const {
    columnId,
    embeddedIndex,
    value: selectedValue,
    roomId: editRoomId,
    index
  } = useSelector(({ app }) => getSelectedChecklist(app));

  const selectedRows = useSelector(({ app }) => getWorkflowSelectedRows(app));
  const showRemoveModal = !fromManageView || selectedRows.length === 0;

  const checklistValue = useSelector(({ app }) =>
    getChecklistValue(app, fieldId, roomId)
  );
  const details = useSelector(({ app }) =>
    getChecklistFieldDetails(app, `${fieldId}`)
  );

  const archived: Array<RoomId> =
    useSelector(({ app }) => getArchivedConversations(app, checklistValue)) ||
    [];
  const locked = useSelector(({ app }) =>
    getLockedStatus(app, roomId, fieldId)
  );
  const isMandatory = useSelector(({ app }) =>
    getWhetherMandatoryField(app, roomId, fieldId)
  );
  const behaviorByFormField = useSelector(({ app }) =>
    getBehaviorByFormField(app, roomFieldFormId || "")
  );
  const behaviorByChecklistField =
    useSelector(({ app }) => getChecklistFieldBehavior(app, roomId, fieldId)) ||
    {};
  const valueStatus = useSelector(({ app }) =>
    getRoomFieldValueStatus(app, fieldId, roomId)
  );
  const updating = valueStatus === dataStages.updating;

  const behavior = roomFieldFormId
    ? behaviorByFormField
    : behaviorByChecklistField;

  const dependentOptions = behavior.options;
  const currentBehavior = behavior.current;

  const settings: any = getSettings(details ? details.get("settings") : "{}");
  const value = settings?.showArchived
    ? checklistValue?.value || {}
    : filterArchived(checklistValue?.value || {}, archived);

  const existingValues = fromManageView ? selectedValue : value;

  const workflowData = useSelector(({ app }) =>
    getWorkflow(app, `${settings?.workflow ?? ""}`)
  );
  const currentUser = useSelector(({ app }) => getCurrentUser(app));

  // $FlowFixMe - Optional chaining not yet supported
  const target = details?.get("target") || false;
  const { chatrooms, result } = useMemo(() => {
    const { entities: { chatrooms = {} } = {}, result = [] } =
      existingValues || {};
    return { chatrooms, result };
  }, [existingValues]);

  const noValue = R.isEmpty(result);

  // id of chatroom whose checklist value you want to edit after clicking on "Reassign versions"
  const [removeLinkModalState, setRemoveLinkModalState] = useState({
    open: false,
    roomId: null
  });

  const {
    value: isDropdownVisible,
    setTrue: openDropdown,
    setFalse: closeDropdown
  } = useBoolean(false);

  const [modalState, setModalState] = useState({
    open: false,
    roomId: null,
    edit: false
  });

  const closeModal = () => setModalState(prev => ({ ...prev, open: false }));

  const modal = {
    state: modalState,
    close: closeModal
  };

  const { value: newRoom, setValue: setNewRoom } = useBoolean();

  const dropdown = {
    visible: isDropdownVisible,
    open: openDropdown
  };

  const [selectedRoomIds, autoLinkedRooms] = groupVersionsTogether(
    result,
    chatrooms
  );

  // Below selector causes re-renders on any state change.
  const modalSelectedRooms = useSelector(({ app }) => {
    const { roomId } = modal.state;
    return roomId !== null && roomId !== undefined
      ? getMutualVersions(app)(R.values(chatrooms), roomId)
      : [];
  });

  const modalSelectedRoomIds = modalSelectedRooms.map(
    item => `${item.chatroom.id}`
  );

  const handleClose = () => {
    setNewRoom(false);
    closeDropdown();
  };

  const handleNewRoom = () => {
    setNewRoom(true);
    closeDropdown();
  };

  const setChecklistFieldValue = ({
    roomId,
    id,
    value,
    progress,
    formId,
    httpMethod,
    extraBody
  }: {
    roomId: RoomId,
    id: FieldId,
    value: Object,
    progress: boolean,
    formId: ?number,
    httpMethod?: HttpMethods,
    extraBody?: Object,
    columnId?: ColumnId,
    embeddedIndex?: number
  }) => {
    // Use updateChecklistFromManageView to update embedded linked
    // fields from manage view
    if (fromManageView && columnId) {
      // Update linked field values but don't reflect the changes on
      // manage view, only on checklist.selectedChecklist
      if (!showRemoveModal) {
        return updateSelectedChecklistRequest({
          roomId: editRoomId,
          value,
          index,
          id: `${fieldId}`,
          httpMethod: httpMethod ?? httpMethods.patch,
          extraBody: extraBody ?? null,
          columnId,
          embeddedIndex,
          formId: formId ?? null
        });
      }
      return updateChecklistFromManageView(
        editRoomId,
        value,
        index,
        fieldId,
        httpMethod,
        extraBody,
        columnId,
        embeddedIndex
      );
    } else {
      // To update linked field values on Checklist Dock
      return setChecklistValue({
        roomId,
        id,
        value,
        progress,
        formId,
        httpMethod,
        extraBody,
        columnId,
        embeddedIndex
      });
    }
  };

  const handleCreate = title => {
    const newRoomData: {
      title: string,
      type: string,
      owner: UID | null,
      creator: UID,
      members: UID[],
      templateId: number,
      privacy: string,
      parent?: number,
      groups: number[]
    } = {
      title,
      type: "workflow",
      owner: workflowData.owner || null,
      creator: currentUser.uid,
      members: workflowData.members,
      templateId: settings.workflow,
      privacy: workflowData.privacy,
      groups: workflowData?.groups ?? []
    };

    // Assign creator as owner depending on setting
    if (
      !newRoomData.owner &&
      workflowData?.settings?.creatorIsOwner !== false
    ) {
      newRoomData.owner = currentUser.uid;
    }

    // Make creator as member depending on setting
    if (workflowData?.settings?.creatorIsParticipant !== false) {
      if (newRoomData.owner) {
        newRoomData.members = R.uniq([
          newRoomData.owner,
          currentUser.uid,
          ...(newRoomData.members || [])
        ]);
      } else {
        newRoomData.members = R.uniq([
          currentUser.uid,
          ...(newRoomData.members || [])
        ]);
      }
    }

    if (settings.child) {
      newRoomData.parent = parseInt(roomId, 10);
    }

    dispatch(
      setChecklistFieldValue({
        roomId,
        id: fieldId,
        value: {
          value: {
            chatroom: newRoomData,
            linkAllVersions: true
          },
          type: "link"
        },
        progress: true,
        formId,
        httpMethod: httpMethods.patch,
        columnId,
        embeddedIndex
      })
    );

    handleClose();
  };

  const handleVersionClick = id => {
    setModalState({
      open: true,
      edit: fromManageView ? true : false,
      roomId: id
    });
  };

  const selectNewRoomVersions = id => {
    setModalState({
      open: true,
      edit: true,
      roomId: id
    });
  };

  const removeLink = originChatroomId => {
    dispatch(
      setChecklistFieldValue({
        roomId,
        id: fieldId,
        value: {
          value: {
            originChatroomId
          },
          type: "link"
        },
        progress: true,
        formId,
        columnId,
        httpMethod: httpMethods.delete,
        embeddedIndex
      })
    );
  };

  const removeRoom = originChatroomId => {
    dispatch(
      setChecklistFieldValue({
        roomId,
        id: fieldId,
        value: {
          value: { originChatroomId },
          type: "link"
        },
        httpMethod: httpMethods.delete,
        columnId,
        formId,
        progress: true,
        embeddedIndex
      })
    );
  };

  const handleRemove = id => {
    if (!target) {
      const originChatroomId = getOriginChatroomId(id, result, chatrooms);
      removeRoom(originChatroomId);
    } else {
      setRemoveLinkModalState({
        open: true,
        roomId: id
      });
    }
  };

  const editParentField = ({ newValue, sourceChatroom }) => {
    dispatch(
      setChecklistFieldValue({
        roomId,
        id: fieldId,
        value: {
          value: newValue,
          type: "link"
        },
        httpMethod: httpMethods.patch,
        extraBody: {
          sourceChatroom
        },
        progress: true,
        formId,
        columnId,
        embeddedIndex
      })
    );
  };

  const handledEditParentField = value => {
    if (!removeLinkModalState.roomId) return;

    const originChatroomId = getOriginChatroomId(
      removeLinkModalState.roomId,
      result,
      chatrooms
    );

    editParentField({
      newValue: value,
      sourceChatroom: originChatroomId
    });
  };

  const handleRemoveLink = () => {
    if (!removeLinkModalState.roomId) return;

    const originChatroomId = getOriginChatroomId(
      removeLinkModalState.roomId,
      result,
      chatrooms
    );

    // Don't remove link if room ID is undefined
    if (originChatroomId) {
      removeLink(originChatroomId);
    }
  };

  const selectChatrooms = value => {
    dispatch(
      setChecklistFieldValue({
        roomId,
        id: fieldId,
        value: {
          value,
          type: "link"
        },
        progress: true,
        formId,
        httpMethod: httpMethods.patch
      })
    );
    //if (promptCallback) promptCallback();
  };

  const handleSelect = id => {
    selectChatrooms({ chatroom: id, linkAllVersions: true });
    handleClose();
  };

  const handleOpenDropdown = useCallback(() => {
    dropdown.open();
  }, []);

  const hideRemoveLinkModal = () => {
    setRemoveLinkModalState({ open: false, roomId: null });
  };

  const dependentInclude = getDependentOptions(
    currentBehavior,
    behaviorToSettings[behaviors.dependentLinkedFieldInclude],
    dependentOptions
  );

  const dependentExclude = getDependentOptions(
    currentBehavior,
    behaviorToSettings[behaviors.dependentLinkedFieldExclude],
    dependentOptions
  );

  return (
    <VStack alignItems="start" width="100%">
      {!noValue && (
        <SelectionMultiple
          roomId={roomId}
          fieldId={fieldId}
          value={selectedRoomIds}
          autoLinkedRooms={autoLinkedRooms}
          onVersionClick={handleVersionClick}
          showMetaData={settings.showMetaData}
          handleRemove={handleRemove}
          showFieldNames={settings.showCompressedPreview}
          sortBy="added"
          fields={settings.fields}
          disabled={locked}
          alwaysAllowManualLinks={Boolean(settings.alwaysAllowManualLinks)}
          fromManageView={fromManageView ?? false}
        />
      )}

      {dropdown.visible && (
        <Dropdown
          roomId={roomId}
          workflow={settings.workflow}
          type="workflow"
          selectedValue={result}
          handleClose={handleClose}
          handleNewRoom={handleNewRoom}
          handleSelect={handleSelect}
          handleCreate={handleCreate}
          handleRemove={handleRemove}
          create={settings.create}
          select={settings.select}
          groupVersions
          selectNewRoomVersions={selectNewRoomVersions}
          hideSelectedVersions
          dependentInclude={dependentInclude}
          dependentExclude={dependentExclude}
          alwaysShowRevisionIcon={Boolean(settings.alwaysAllowManualLinks)}
          fromManageView={fromManageView ?? false}
          showArchived={settings.showArchived}
        />
      )}

      {newRoom && !dropdown.visible && (
        <NewRoom
          settings={settings || {}}
          handleClose={handleClose}
          handleCreate={handleCreate}
          isMandatory={isMandatory}
        />
      )}

      <NewMultiSelectConversation
        settings={settings || {}}
        roomId={roomId}
        fieldId={fieldId}
        newRoom={newRoom}
        dropdown={dropdown.visible}
        handleDropdown={handleOpenDropdown}
        updating={updating}
        disabled={locked}
        isMandatory={isMandatory}
      />

      {modal.state.open && !R.isNil(modal.state.roomId) && (
        <MultiModal
          open
          roomId={modal.state.roomId}
          selectedRooms={modalSelectedRoomIds}
          // If autoLink is true, then the first value
          // will have `linkAllVersions` set to true
          autoLink={modalSelectedRooms[0]?.linkAllVersions}
          defaultEdit={modal.state.edit}
          onSuccess={selectChatrooms}
          onClose={modal.close}
        />
      )}

      {!noValue && removeLinkModalState.open && (
        <RemoveLinkModal
          open
          onClose={hideRemoveLinkModal}
          roomId={roomId}
          fieldId={fieldId}
          sourceChatroomId={removeLinkModalState.roomId}
          handledEditParentField={handledEditParentField}
          handleRemoveLink={handleRemoveLink}
        />
      )}
    </VStack>
  );
};

export default Multi;
